<template>
  <b-modal v-model="isOpen" hide-footer hide-header :data-theme="theme">
    <section class="close-button">
      <button @click="setVisibility">X</button>
    </section>
    <section class="buy-modal-body">
      <section class="buy-modal-body-content">
        <h1 class="title">Compre agora 🔥</h1>
        <section class="body-content">
          <video
            v-show="src"
            ref="videoPlayer"
            class="video-js"
            :controls="true"
            :autoplay="true"
          >
            <source :src="src" :type="type" />
          </video>
          <h2 class="course-name" v-if="course.title">{{ course.title }}</h2>
          <p class="description" v-if="description" v-html="description"></p>
          <button class="buy-button" @click="buy">
            {{ confirmedButtonText }}
          </button>
        </section>
      </section>
    </section>
  </b-modal>
</template>

<script>
import "video.js/dist/video-js.css";
import videojs from "video.js";
import '@devmobiliza/videojs-vimeo/dist/videojs-vimeo.esm';
import 'videojs-youtube';
// Resources
import CourseService from "@/services/resources/CourseService";
const serviceCourse = CourseService.build();

export default {
  data: () => ({
    isOpen: false,
    course: '',
    // Options
    src: null,
    show_player: false,
    type: "video/youtube",
    description: "",
    confirmedButtonText: "Quero aproveitar a promoção",
  }),
  computed: {
    theme() {
      return this.$store.state.globalSettings.theme;
    },
  },
  methods: {
    buy() {
      window.open(this.linkCheckout);
    },
    setVisibility() {
      this.isOpen = !this.isOpen;
    },
    getPlayer() {
      this.player = videojs(this.$refs.videoPlayer, {}, function onPlayerReady() {
      });
      this.show_player = true;
      this.$forceUpdate();
    },
    getMetaCourse() {
      this.src = null;
      let url =
        this.course.id +
        "/meta?keys[]=sale_link&keys[]=sale_video&keys[]=sale_video_thumb&keys[]=sale_button_txt&keys[]=sale_description&keys[]=sale_video_type&keys[]=tipo_venda";

      serviceCourse
        .read(url)
        .then((resp) => {
          if (resp.tipo_venda === "tela") {
            this.$router.push("/curso/venda/" + this.course.id);
          }
          if (resp.tipo_venda === "modal") {
            this.setVisibility();
            this.linkCheckout = resp.sale_link;
            if (resp.sale_video_type === "vimeo") {
              this.type = "video/vimeo";
            }
            this.description = resp.sale_description;
            if (resp.sale_button_txt) {
              this.confirmedButtonText = resp.sale_button_txt;
            }
            if (resp.sale_video) {
              this.src = resp.sale_video;
              setTimeout(() => {
                this.getPlayer();
              }, 1000);
            }
          }
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
  },
  mounted() {
    this.$root.$on("buy-course-modal-v2", async (data) => {
      this.course = data;
      this.getMetaCourse();
    });
  },
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
[data-theme="light"] {
  --background: #ffffff;
  --text-color: #16171a;
  --closes-bg: #f7f7f7;
}
[data-theme="dark"] {
  --background: #16171a;
  --text-color: #ffffff;
  --closes-bg: #1e2024;
}

.buy-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-content {
    max-width: 350px;
  }

  .body-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }
}
.close-button {
  display: flex;
  justify-content: flex-end;
  button {
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: var(--closes-bg);
    padding: 15px;
    color: #6f6f6f;
    font-size: 15px;
    font-weight: 700;
  }
}
.default-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: var(--text-color);
}
.title {
  @extend .default-text;
  margin-bottom: 30px;
}
.video-js {
  border-radius: 10px;
  display: flex;
  align-self: center;
  width: 100%;
  max-height: 160px;
}

.course-name {
  @extend .default-text;
  margin-top: 10px;
  word-break: break-all;
}
.description {
  @extend .default-text;
  font-size: 15px;
  font-weight: 400;
  * {
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.buy-button {
  @extend .default-text;
  font-weight: 700;
  font-size: 14px;
  color: #16181a;
  min-width: 340px;
  outline: none;
  border: none;
  background: var(--maincolor);
  border-radius: 50px;
  padding: 15px 0;
  transition: ease-in-out 200ms;

  &:hover {
    transform: scale3d(0.9, 0.9, 0.9);
  }
}
</style>
