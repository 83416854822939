<template>
  <main v-if="isOpen" class="panels-modal" :data-theme="theme">
    <div class="header_panel">
      <h1 class="title mr-3">{{ $t("modal_painel.v2.title") }}</h1>
      <button v-if="!isStudent" class="newPainel" @click="openNew">Novo Painel</button>
    </div>

    <section v-if="isLoading" class="loading">
      <b-spinner label="Loading..."></b-spinner>
    </section>
    <template v-else>
      <!-- 4 or less panels -->
      <section class="panels pt-3" v-if="panels.length <= 4">
        <section
          v-for="(panel, index) in panels"
          :key="index"
          class="panel"
          :class="{ active: panel.active }"
          @click="selectPanel(panel)"
        >
          <img
            :src="panel.logo"
            :alt="`Logo do curso ${panel.name}`"
            v-if="panel.logo"
            width="150"
            height="150"
          />
          <section
            v-else
            class="no-image"
            :class="`index-${index + 1}`"
            :style="{ background: randonBackground() }"
          >
            <img
              src="@/assets/vector.png"
              alt="imagem do busto de uma pessoa"
              width="75"
              height="75"
            />
          </section>
          <p class="panel-name">
            {{ panel.name }}
          </p>
        </section>
      </section>
      <!-- More than 4 panels  -->
      <section class="panels pt-3 small-gap" v-else>
        <section
          v-for="(panel, index) in panels"
          :key="index"
          class="large-panel"
          :class="{ active: panel.active }"
          @click="selectPanel(panel)"
        >
          <img
            :src="panel.logo"
            :alt="`Logo do curso ${panel.name}`"
            v-if="panel.logo"
            width="34"
            height="34"
          />
          <section
            v-else
            class="no-image"
            :class="`index-${index + 1}`"
            :style="{ background: randonBackground() }"
          >
            <img
              src="@/assets/vector.png"
              alt="imagem do busto de uma pessoa"
              width="17"
              height="17"
            />
          </section>
          <p class="panel-name">
            {{ panel.name }}
          </p>
        </section>
      </section>
    </template>
  </main>
</template>

<script>
import Cookies from "js-cookie";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import { mapGetters } from "vuex";

export default {
  data: () => ({
    isOpen: false,
    panels: [],
    permission: false,
    isLoading: false,
  }),
  computed: {
    ...mapGetters(["currentSite"]),
    allSites() {
      return this.$store.getters.allSites;
    },
    currentAuth() { 
      return this.$store.getters.currentAuth;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    isStudent() {
      return (
        this.$store.getters?.currentSite?.currentMember?.role === "student"
      );
    },
  },
  methods: {
    openNew() {
      this.$root.$emit("bv::show::modal", "modal-new-painel", "#btnShow");
      this.$root.$emit('show-panel-modal-v2');   
    },
    setVisibility() {
      this.isOpen = !this.isOpen;
      this.$emit("opened");
    },
    async getPanels() {

      Cookies.set("memberAuth", this.currentAuth, { expires: 365 });
      this.panels = this.allSites.map((item) => ({
        ...item,
        active: item.id === this.currentSite.currentSite.id,
      }));
    
    },
    selectPanel(data) {
      this.setVisibility();
      if (data.active) return;
      this.$store.dispatch("blogSelected", data);    
    },
    randonBackground() {
      const color1 = this.getRandomColor();
      const color2 = this.getRandomColor();
      return `linear-gradient(160deg, ${color1}, ${color2})`;
    },
    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  mounted() {
    this.$root.$on("show-panel-modal-v2", async () => {
      this.isLoading = true;
      this.setVisibility();
      await this.getPanels();
      this.isLoading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.header_panel {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-right: 6px;
  padding-left: 6px;
}
.newPainel {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  padding: 0px 25px;
  width: max-content;
}
@media screen and (max-width: 768px) {
  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 45px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 13px;
    width: max-content;
  }
}
  
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

[data-theme="light"] {
  --background: #ffffff;
  --text-color: #16171a;
  --panel-border: 1px solid rgba(22, 23, 26, 0.3);
}
[data-theme="dark"] {
  --background: #16171a;
  --text-color: #ffffff;
  --panel-border: 1px solid rgba(255, 255, 255, 0.3);
}

.panels-modal {
  width: 100vw;
  min-height: 100vh;
  background: var(--background);
  padding-top: 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  .title {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    white-space: nowrap;
    color: var(--text-color);
  }
  @media screen and (max-width: 768px) {
    .title {
      font-family: "Montserrat Alternates";
      font-weight: 600;
      font-size: 26px;
      line-height: 39px;
      white-space: nowrap;
      color: var(--text-color);
    }
    
  }
  .panels {
    display: flex;
    gap: 70px;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 90vw;
    overflow-y: auto;
    padding-top: 5px;
    padding-bottom: 40px;

    &.small-gap {
      gap: 30px;
    }

    .panel {
      max-width: 150px;
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 15px;
      cursor: pointer;
      transition: ease-in-out 200ms;
      img {
        width: 150px;
        height: 150px;
        object-fit: cover;
      }
      .panel-name {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
        color: var(--text-color);
      }

      .no-image {
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 1px solid var(--background);

        img {
          width: 75px;
          height: 75px;
          border: none;
        }
      }

      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        .panel-name {
          font-weight: 600;
        }
      }

      &.active {
        .no-image {
          border: 1px solid var(--contrast-bg);
        }
        .panel-name {
          font-weight: 600;
        }
      }
    }

    .large-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 25px;
      width: 260px;
      gap: 10px;
      cursor: pointer;
      transition: ease-in-out 200ms;

      border: var(--panel-border);
      border-radius: 10px;

      .no-image {
        width: 34px;
        height: 34px;
        border-radius: 10px;

        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 17px;
          height: 17px;
        }
      }
      .panel-name {
        max-width: 220px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: "Montserrat";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        color: var(--text-color);
      }

      &:hover {
        transform: scale3d(1.1, 1.1, 1.1);
        .panel-name {
          font-weight: 600;
        }
      }

      &.active {
        border: 1px solid var(--contrast-bg);
        .panel-name {
          font-weight: 600;
        }
      }
    }
  }
}
</style>
